@use 'common'
@use 'sass:math'

.sections
	position: relative

.name
	margin-bottom: -2rem
	@media (min-width: common.$break48)
		margin-bottom: 0

.sectionsNav
	display: none
	@media (min-width: common.$break48)
		display: flex
		flex-wrap: wrap
		position: sticky
		top: 0
		background-color: #ffffff
		padding: 1.5rem 2rem 1rem

.mobileSectionsNav
	display: none
	&.isOpen
		display: flex
		flex-direction: column
		align-items: center

.menuButton
	display: flex
	align-items: center
	margin: auto
	background-color: transparent
	border: none
	color: inherit
	padding-top: 4.5rem
	margin-bottom: 1.75rem
	@media (min-width: common.$break48)
		display: none

.menuButtonLabel
	font-size: 1.5rem
	line-height: 1.625rem
	font-weight: 700

.icon
	display: flex
	font-size: 1rem
	margin-left: 0.75rem

.navTitle
	font-size: 1.25rem
	line-height: 1.5625rem
	font-weight: 700
	margin-right: 1.875rem
	margin-bottom: 1rem
	text-decoration: none
	&:hover
		background: linear-gradient(0deg, #AA94FA 0%, #FFF 100%)

.sectionTitle
	font-size: 1.5rem
	line-height: 1.625rem
	font-weight: 700
	padding-top: 3.5rem
	margin: 0 0 1.75rem
	padding-top: 3.5rem

.sectionTitleDesktop
	display: none
	@media (min-width: common.$break48)
		display: flex
		font-size: 2rem
		line-height: 2.1875rem
		padding-top: 10rem
		margin-top: 0

.content
	margin: 0 -1rem -3.5rem
	@media (min-width: 40rem)
		margin: 0 -2rem
	@media (min-width: common.$break48)
		margin: 0 -2rem -7rem
