@use 'common'

.wrapper
	background-color: var(--background-color)
	padding-block: 1rem

.in
	display: flex
	flex-direction: column
	align-items: center

.topMobile
	display: flex
	align-items: center
	justify-content: space-between
	width: 100%
	@media (min-width: common.$break62)
		display: none

.mobileLogo,
.mobileLogoOpen
	font-size: 3.375rem
	line-height: 0

.mobileLogoOpen
	color: common.$c-purple

.menuButton
	display: flex
	background-color: transparent
	color: inherit
	border: transparent
	padding: 0
	font-size: 1.5625rem
	color: common.$c-purple

.mobileHeader
	display: none

	&.isOpen
		padding-block: 1rem
		display: flex
		flex-direction: column
		position: absolute
		top: 0
		left: 0
		background: linear-gradient(0deg, #AA94FA 0%, #FFF 95.60%)
		width: 100%
		padding-bottom: 2.8125rem
		.topMobile
			margin-bottom: 2rem

.top
	display: none
	@media (min-width: common.$break62)
		display: flex
		justify-content: space-between
		width: 100%

.mobileLinks
	display: flex
	flex-direction: column
	list-style-type: none
	justify-content: flex-end
	flex-wrap: wrap
	margin: 0
	width: 100%
	padding: 0

.mobileLink
	display: flex
	font-weight: 700
	font-size: 1.5rem
	line-height: 1.625rem
	text-decoration: none
	margin: 13px 0

.links
	display: none
	@media (min-width: common.$break62)
		list-style-type: none
		display: flex
		justify-content: flex-end
		flex-wrap: wrap
		margin: 0
		width: 100%

.itemIn
	display: flex
	align-items: center

.link
	display: inline-block
	margin: 0.5em 0 0.5em 1em
	text-decoration: none
	font-size: 2rem
	line-height: 2.1875rem
	font-weight: 700
	&:hover
		color: common.$c-purple
		background: linear-gradient(0deg, #AA94FA 0%, #FFF 100%)

.itemIcon
	margin-left: .25rem
	width: 1.75rem

.specialLinkWrapper
	display: flex

.specialLink
	display: flex
	align-items: center
	margin-bottom: 13px
	@media (min-width: common.$break62)
		margin-bottom: 0

.languageSwitcher
	display: flex
	margin-top: .8125rem
	@media (min-width: common.$break62)
		margin-top: 0
		margin-left: 1.5625rem

.is_active
	color: common.$c-purple
	text-decoration: underline
	@media (min-width: common.$break62)
		background: linear-gradient(0deg, #AA94FA 0%, #FFF 100%)
		text-decoration: none
