@use 'common'

.languageSwitcher
	display: flex
	align-items: center
	font-size: 1rem
	color: inherit

.isPurple
	.languageIcon,
	.languageLabel
		color: common.$c-purple

.language
	display: flex
	align-items: center
	text-decoration: none

.languageIcon
	display: flex
	font-size: 26px
	color: inherit

.languageLabel
	font-size: 20px
	font-weight: 700
	line-height: 25px
	padding-left: 10px
	text-decoration: none
	text-transform: uppercase
	color: inherit
