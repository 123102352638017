@use 'common'

.wrapper
	//

.cards
	display: flex
	flex-wrap: wrap

.card
	width: 100%
	margin-top: 5.75rem
	@media (min-width: common.$break48)
		padding: 1rem
		width: 50%

.title
	font-size: 1.5rem
	line-height: 1.625rem
	font-weight: 700
	padding-bottom: .75rem
	@media (min-width: common.$break48 )
		font-size: 2rem
		line-height: 2.1875rem
.text
	font-size: 1rem
	line-height: 1.125rem
	font-weight: 400
	padding-top: .75rem
	@media (min-width: common.$break48 )
		font-size: 1.25rem
		line-height: 1.5625rem
