@use 'common'

.wrapper
	display: flex
	flex-direction: column
	@media (min-width: common.$break48)
		flex-direction: row

.image
	width: 75%
	margin-top: 1.25rem
	@media (min-width: common.$break48)
		width: 50%
		margin-top: 0

.column
	@media (min-width: common.$break48)
		width: 50%

.title
	font-size: 2.875rem
	line-height: 3.125rem
	font-weight: 700
	@media (min-width: common.$break48)
		font-size: 4.25rem
		line-height: 68px

.linkDesktop
	display: none
	@media (min-width: common.$break48)
		display: block
		margin-top: 3rem

.linkMobile
	display: flex
	justify-content: center
	margin-top: 2.3125rem
	@media (min-width: common.$break48)
		display: none
