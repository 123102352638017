@use 'common'
@use 'sass:math'

.wrapper
	padding: 3.05176rem 0

.pageTitle
	font-size: 1.95312rem
	font-weight: 600
	line-height: 1.125
	@media (min-width: common.$break48)
		line-height: calc(3.05176rem + 10px)
		font-size: 3.05176rem

.lecturers
	display: flex
	flex-wrap: wrap

.button
	display: flex
	justify-content: center
	margin-top: 1rem
