@use 'common'

.wrapper
	display: flex
	flex-direction: column

.title
	font-size: 1.5rem
	line-height: 1.625rem
	font-weight: 700
	@media (min-width: common.$break48)
		font-size: 2rem
		line-height: 2.1875rem

.partners
	display: flex
	flex-wrap: wrap

.partner
	width: 100%
	padding: 1rem 1rem 1rem 0
	@media (min-width: common.$break48)
		width: 50%
	@media (min-width: common.$break62)
		width: 25%
