@use 'common'

.wrapper
	//

.fileOrLink
	margin: 1rem 0

.link
	margin-top: 2.5rem
