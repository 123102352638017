@use 'common'
@use 'sass:math'

.lecturer
	display: flex
	flex-direction: column
	align-items: flex-start
	padding: .4375rem
	width: 100%
	@media (min-width: common.$break48)
		width: 50%
	@media (min-width: common.$break62)
		width: math.div(100%, 3)
	@media (min-width: common.$break75)
		width: 25%

.image
	position: relative
	aspect-ratio: 16/13
	width: 100%
	height: auto
	border-radius: 150px
	overflow: hidden

.content
	font-weight: 300
	margin-top: 1.4375rem

.name,
.text
	font-size: 1rem
	line-height: 1.125rem
	@media (min-width: common.$break48)
		font-size: 1.25rem
		line-height: 1.5625rem

.name
	font-weight: 700

.text
	font-weight: 400
