@use 'common'

.wrapper
	position: relative
	cursor: pointer
	text-decoration: none
	border: none
	background-color: transparent

	&:hover
		background: linear-gradient(0deg, #AA94FA 0%, #FFF 100%)

	&:disabled
		opacity: 0.5
		cursor: default

	&.is_fullWidth
		width: 100%

	&.is_size_small
		padding: 0 0.25rem
		font-size: 1.25rem
		font-weight: 700
		line-height: 1.5625rem

	&.is_size_medium
		padding: 0 0.25rem
		font-size: 1.5rem
		line-height: 1.625rem
		font-weight: 700
		@media (min-width: common.$break48)
			font-size: 2rem
			line-height: 2.1875rem

	&.is_variant_primary
		color: common.$c-purple
		border-left: 3px solid common.$c-purple
		border-right: 3px solid common.$c-purple
