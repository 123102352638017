@use 'common'
@use 'sass:math'

.wrapper
	//

.lecturersMobile
	+common.hideHorizontalScrollBar
	display: flex
	overflow-x: auto
	flex-wrap: nowrap
	scroll-snap-type: x mandatory
	@media (min-width: common.$break48)
		display: none

.lecturers
	display: none
	@media (min-width: common.$break48)
		display: flex
		flex-wrap: wrap

.lecturer
	display: flex
	width: 60%
	flex-shrink: 0
	scroll-snap-align: start
	padding: 0.5rem
	@media (min-width: common.$break48)
		width: 100%

.link
	display: flex
	justify-content: center
	margin-top: 2rem
	@media (min-width: common.$break48)
		justify-content: flex-start
