@use 'sass:math'

// Normalizes height to 1em while preserving aspect ratio
=normalizeSize($intrinsicWidth, $intrinsicHeight)
	$height: 1em

	width: math.div($intrinsicWidth, $intrinsicHeight) * $height
	height: $height

.icon
	// Add all svg icons and corresponding dimensions
	&-arrow
		+normalizeSize(32, 20)
	&-arrowLeft
		+normalizeSize(36, 32)
	&-arrowLink
		+normalizeSize(111, 47)
	&-arrowRight
		+normalizeSize(36, 32)
	&-close
		+normalizeSize(35, 30)
	&-download
		+normalizeSize(111, 47)
	&-file
		+normalizeSize(49, 61)
	&-globe
		+normalizeSize(49, 61)
	&-hamburger
		+normalizeSize(31, 25)
	&-instagram
		+normalizeSize(41, 40)
	&-languageGlobe
		+normalizeSize(32, 32)
	&-linkedin
		+normalizeSize(38, 37)
	&-logoMobile
		+normalizeSize(75, 55)
	&-play
		+normalizeSize(49, 61)
	&-twitter
		+normalizeSize(40, 35)
