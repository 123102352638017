@use '../variables'

=hideHorizontalScrollBar()
	overflow-x: auto

	-webkit-overflow-scrolling: touch
	scrollbar-width: none
	scrollbar-height: none
	-ms-overflow-style: none

	&::-webkit-scrollbar
		display: none
