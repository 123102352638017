@use 'common'
@use 'sass:color'

.wrapper
	a
		font-weight: 600
		color: common.$primary-color
		transition: color 0.2s

		&:hover, &:focus-visible
			color: color.adjust(common.$primary-color, $lightness: 15%)

	h1
		font-size: 2.875rem
		line-height: 3.125rem
		@media (min-width: common.$break48)
			font-size: 4.25rem
			line-height: 4.25rem

	h2
		font-size: 2rem
		line-height: 2.1875rem
		@media (min-width: common.$break48)
			font-size: 3rem
			line-height: 3.25rem

	h3
		font-size: 2.5rem

	p
		font-size: 16px
		font-weight: 700
		line-height: 1.125rem
		@media (min-width: common.$break48)
			font-size: 1.25rem
			line-height: 1.5625rem

	em
		font-style: normal
		color: common.$primary-color

	[data-contember-align="center"]
		text-align: center

	[data-contember-align="end"]
		text-align: end
