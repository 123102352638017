@use 'common'
@use 'sass:math'

.wrapper
	padding: 2rem 0
	@media (min-width: common.$break48)
		padding: 3.05176rem 0

.carouselHeader
	display: flex
	justify-content: space-between
	align-items: center

.navigation
	display: flex

.carouselWrapper
	display: flex
	align-items: center
	margin-left: .5625rem

.carousel
	+common.hideHorizontalScrollBar
	display: flex
	overflow-x: auto
	flex-wrap: nowrap
	scroll-snap-type: x mandatory
	padding: 0 0 2rem

.itemWrapper
	display: flex
	width: 80%
	flex-shrink: 0
	scroll-snap-align: start
	padding: 0.5rem
	@media (min-width: common.$break48)
		width: calc((100% / 2) - 30px)
	@media (min-width: common.$break62)
		width: calc((100% / 3) - 30px)

	&:nth-last-child(1)
		.item
			&::before
				width: 0

.linkWrapper
	display: flex
	justify-content: center
	@media (min-width: common.$break48)
		justify-content: flex-start

.arrowWrapperPrevious
	display: none
	@media (min-width: common.$break48)
		display: block
		padding: 0 .75rem 0 0

.arrowWrapperNext
	display: none
	@media (min-width: common.$break48)
		display: block
		padding: 0 0 0 .75rem

.arrow
	display: flex
	padding: 0
	font-size: 2.5rem
	border: none
	visibility: hidden
	background-color: transparent
	&:hover
		color: #fff

.previous
	transform: rotate(-180deg)

.isVisible
	visibility: visible
