@use 'common'
@use 'sass:math'

.newsPost
	width: 100%
	display: flex
	flex-direction: column
	background: #fff
	margin: .9375rem .9375rem 2.1875rem
	@media (min-width: common.$break48)
		width: calc(50% - 30px)
	@media (min-width: common.$break75)
		width: calc((100% /3) - 30px)

	&:hover
		.image
			transform: scale(1.1)

		.title
			color: common.$c-purple
			background: linear-gradient(0deg, #AA94FA 0%, #FFF 100%)

.fullWidth
	width: 100%
	margin: .9375rem

.link
	text-decoration: none

.imageWrapper
	overflow: hidden

.image
	position: relative
	aspect-ratio: 16/9
	width: 100%
	height: 100%
	display: flex
	line-height: 0
	transition: transform .5s

.title,
.date
	font-size: 1rem
	line-height: 1.125rem
	@media (min-width: common.$break48)
		font-size: 1.25rem
		line-height: 1.5625rem

.date
	font-weight: 400
	padding: 11px 0

.title
	font-weight: 700
	margin: 0
