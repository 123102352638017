@use 'common'

.wrapper
	display: flex
	flex-direction: column

.title
	font-size: 2rem
	font-weight: 700
	line-height: 2.1875rem

.images
	display: grid
	grid-template-columns: 1fr
	@media (min-width: common.$break375)
		grid-template-columns: 1fr 2fr
	@media (min-width: common.$break48)
		display: flex
		flex-wrap: wrap

.imageWrapper
	padding: 1rem 1rem 1rem 0
	&:nth-child(1)
		@media (min-width: common.$break48)
			width: 25%
	&:nth-child(2)
		grid-row: span 2
		@media (min-width: common.$break48)
			width: 50%
	&:nth-child(3)
		@media (min-width: common.$break48)
			width: 25%

.imageText
	font-weight: 700
	font-size: 1rem
	line-height: 1.125rem
	margin-top: .25rem
	@media (min-width: common.$break48)
		font-size: 1.25rem
		line-height: 1.5625rem
		margin-top: .75rem

.link
	display: flex
	justify-content: center
	margin-top: 1.75rem
	@media (min-width: common.$break48)
		justify-content: flex-start
