@use 'common'

.wrapper
	display: flex
	flex-direction: column

.title
	font-size: 2rem
	font-weight: 700
	line-height: 2.1875rem
	@media (min-width: common.$break48)
		font-size: 48px
		line-height: 3.25rem

.text
	font-size: 16px
	font-weight: 700
	line-height: 1.0625rem
	@media (min-width: common.$break48)
		font-size: 1.25rem
		line-height: 1.5625rem

.graduates
	display: flex
	flex-wrap: wrap
	@media (min-width: common.$break48)
		margin-left: -2rem

.graduate
	display: flex
	width: 100%
	@media (min-width: common.$break48)
		width: 50%

.graduateIn
	display: flex
	background-color: common.$c-lightPurple
	color: common.$c-purple
	width: 100%
	margin: 1rem 0
	padding: 1.0625rem .9375rem 1.625rem
	@media (min-width: common.$break48)
		margin: 1rem 2rem
		padding: 1.25rem 1.625rem 3.5rem

.image
	display: flex
	min-width: 5.75rem
	width: 5.75rem
	height: 5.75rem
	border-radius: 50%
	overflow: hidden

.graduateInfo
	margin-left: 1.125rem

.graduateName,
.graduateText
	font-size: 1rem
	line-height: 1.125rem
	@media (min-width: common.$break48)
		font-size: 1.25rem
		line-height: 1.5625rem

.graduateName
	font-weight: 700
