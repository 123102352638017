@use 'common'

.wrapper
	display: flex
	align-items: flex-start
	justify-content: space-between
	background-color: #F5F5F5
	text-decoration: none

	padding: .625rem

	&:hover
		color: common.$c-purple
		.icon
			color: common.$c-purple
	@media (min-width: common.$break48)
		padding: .75rem 1.25rem
		align-items: center

.iconTitleWrapper
	display: flex

.titleWrapper
	display: flex
	flex-direction: column
	padding-right: 1rem
	padding-left: .625rem
	@media (min-width: common.$break48)
		padding-left: 0
		flex-direction: row
		align-items: center

.title
	font-size: 1rem
	line-height: 1.125rem
	font-weight: 700
	@media (min-width: common.$break48)
		font-size: 1.25rem
		line-height: 1.5625rem
		padding-left: 1.25rem

.fileInfo
	font-size: 1rem
	line-height: 1.125rem
	display: flex
	align-items: center
	@media (min-width: common.$break48)
		font-size: 1.25rem
		line-height: 1.5625rem

.fileSize,
.domain
	@media (min-width: common.$break48)
		padding-left: 1.25rem

.fileType
	padding-left: .25rem

.icon
	display: flex
	font-size: 2.1875rem
	@media (min-width: common.$break48)
		align-items: center
		font-size: 3rem
