@use 'common'

.wrapper
	background: linear-gradient(0deg, #AA94FA 0%, #FFF 95.60%)
	padding-bottom: 1.5rem
	margin-top: 2rem
	@media (min-width: common.$break62)
		margin-top: 0
		padding: 3.5rem 0 1.875rem 0
	@media (min-width: common.$break75)
		padding: 3.5rem 0 1.875rem .75rem

.container
	display: flex
	flex-direction: column
	justify-content: space-between
	color: common.$c-purple
	@media (min-width: common.$break62)
		flex-direction: row

.logos,
.columns
	@media (min-width: common.$break62)
		width: 50%

.logos
	display: flex
	flex-direction: column
	align-items: flex-start

.otherLogos
	display: flex
	margin-top: .875rem

.columns
	display: flex
	flex-direction: column
	justify-content: flex-end
	@media (min-width: common.$break62)
		flex-direction: row

.linksWrapper
	display: flex
	margin: 39px 0 85px
	@media (min-width: common.$break62)
		margin: 0

.column
	display: flex
	flex-direction: column
	margin-right: 2rem
	@media (min-width: common.$break62)
		margin-right: 3.125rem
	@media (min-width: common.$break75)
		margin-right: 6.25rem

.columnTitle
	font-style: normal
	font-weight: 700
	font-size: 1.5rem
	line-height: 1.625rem
	margin-bottom: 1.4375rem
	@media (min-width: common.$break62)
		font-size: 2rem
		line-height: 2.1875rem
		margin-bottom: 2.25rem

.link
	display: inline-block
	padding: 0.5em 0
	text-decoration: none
	font-weight: 700
	font-size: 1rem
	line-height: 1.125rem
	color: inherit
	@media (min-width: common.$break62)
		font-size: 1.25rem
		line-height: 1.5625rem

.languageSwitcher
	margin-bottom: 1.8125rem
	@media (min-width: common.$break62)
		margin-bottom: 0
		padding-top: 0.5rem

.madeBy
	color: common.$c-purple
	a
		color: common.$c-purple
		&:hover
			text-decoration: none
