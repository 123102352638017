@use 'common'
@use 'sass:math'

.wrapper
	background-color: #fff
	color: common.$primary-color

.wrapperIn
	max-width: 51.25rem

.link
	display: flex
	justify-content: center
	margin-top: 2.625rem
	@media (min-width: common.$break48)
		justify-content: flex-start

.collapsibleTitleWrapper
	display: flex
	justify-content: space-between
	align-items: center
	cursor: pointer
	border: none
	width: 100%
	background-color: #F5F5F5
	padding: 1.25rem
	font-family: inherit
	margin-top: 1rem
	color: common.$primary-color
	&:hover
		.plus,
		.collapsibleTitle
			color: common.$c-purple

.collapsibleTitle
	margin: 0
	font-size: 1rem
	font-weight: 700
	line-height: 1.125rem
	text-align: start
	padding-right: 1rem
	@media (min-width: common.$break48)
		font-size: 1.25rem
		line-height: 1.5625rem

.collapsibleText
	font-size: 1rem
	font-weight: 400
	line-height: 1.125rem
	border: none
	padding: 1.625rem 1.625rem 2.5rem 1.625rem
	background-color: #F5F5F5
	@media (min-width: common.$break48)
		font-size: 1.25rem
		line-height: 1.5625rem

.collapsibleTextIn
	max-width: 80%

.plus,
.minus
	font-size: 1.25rem
	line-height: 0
	transition: transform .3s

.minus
	color: common.$c-purple
	transform: rotate(180deg)

.isOpen
	.collapsibleTitle
		color: common.$c-purple
