@use 'common'

.container
	display: flex
	justify-content: center

.wrapper
	display: flex
	flex-direction: column
	@media (min-width: common.$break48)
		flex-direction: row

.titleWrapper,
.formWrapper
	@media (min-width: common.$break48)
		width: 50%

.title
	font-size: 1.5rem
	line-height: 1.625rem
	max-width: 30rem
	@media (min-width: common.$break48)
		padding-right: 2rem
		font-size: 2rem
		line-height: 2.1875rem

.form
	display: flex
	flex-direction: column

.inputLabelWrapper
	margin: 2rem 0 1rem

.inputLabel,
.inputDescription
	font-size: 1rem
	line-height: 1.125rem
	@media (min-width: common.$break48)
		font-size: 1.25rem
		line-height: 1.5625rem

.inputLabel
	font-weight: 700

.inputDescription
	color: #9E88F5

.input
	width: 100%
	border: 2px solid common.$c-lightPurple
	height: 4.5rem
	font-size: 1.25rem
	padding: 1rem
	color: common.$c-purple
	&:focus-visible
		border: 2px solid common.$c-purple
		outline: none

.textArea
	font-family: inherit
	font-size: 1rem

.emailToBox
	display: flex
	align-items: center
	padding: .5rem 1rem .625rem
	background-color: common.$c-lightPurple
	width: fit-content
	margin: 1.75rem 0 2.375rem
	@media (min-width: common.$break48)
		padding: 1.25rem 1.25rem 1.25rem 1.5rem

.emailToNameWrapper
	margin-left: 1rem
	@media (min-width: common.$break48)
		margin-left: 2.1875rem

.emailToName,
.emailToDescription
	color: common.$c-purple
	font-size: 1rem
	line-height: 1.125rem
	@media (min-width: common.$break48)
		font-size: 1.25rem
		line-height: 1.5625rem

.emailToName
	font-weight: 700

.image
	position: relative
	border-radius: 50%
	overflow: hidden
	width: 3.0625rem
	height: auto
	aspect-ratio: 1/1
	@media (min-width: common.$break48)
		width: 5.5rem

.submit
	display: flex
	button
		background-color: transparent
